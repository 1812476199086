import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import {
  chakra,
  Box,
  Stack,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { EECS, business, statistics } from "../data/coursework";

const Resume = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const current = `
  UC Berkeley M.S. student in the **Electrical Engineering and Computer Science** (EECS) department. I am advised by Professor Kannan Ramchandran and am affiliated with the **BASICS** and **BAIR** labs.

  Head of Professional Development of [**Traders at Berkeley**](https://traders.berkeley.edu/)

  * Currently leading new member integration and education
  * Previously President, where I ran the club as a whole and overhauled our Intro to Quant Finance Course, with over 150 students per semester
  * Previously Head of External, where I interfaced with sponsors to help run the Berkeley Trading Competition
  * Previously Development Lead, where I led and mentored 5 developers in designing a CLI-based market-making game

  Founder and President of [**Poker at Berkeley**](https://poker.berkeley.edu/)

  Founder and President of [**Web Development at Berkeley**](https://webatberkeley.org/)

  * Founded UC Berkeley's first web-development focused organization, and was president for 3 semesters
  * Taught development and design to over 1000 students through education initiatives
  * Worked with over 30 startups and nonprofits as clients
  * Created a community of over 70 developers, creatives, and entreprenuers

  Drummer, Guitarist, Martial Artist, and Amateur Gymnast
  `;

  const previous = `
  UC Berkeley undergraduate student Majoring in [**Computer Science**](https://eecs.berkeley.edu/), [**Business Administration**](https://haas.berkeley.edu/), and [**Statistics**](https://statistics.berkeley.edu/) with a minor in [**Data Science**](https://data.berkeley.edu/)

  * Certification in Entreprenuership and Technology
  * [Relevant Coursework](#coursework)

  Algorithm Developer Intern at [**Hudson River Trading**](https://www.hudsonrivertrading.com/) | Jun 2023 - Aug 2023

  * High Frequency Trading and ETF Arbitrage Desks

  Course Staff for [**Stat 135: Concepts of Statistics**](https://guide.berkeley.edu/courses/stat/) and [**IEOR 241: Risk Modeling**](https://guide.berkeley.edu/courses/ind_eng/) | Feb 2022 - May 2023

  Quantitative Trading Intern at [**IMC Trading**](https://www.imc.com/) | Jun 2022 - Aug 2022

  * Equity Options - Focus Desk

  Quant Consultant at [**Blockchain at Berkeley**](https://blockchain.berkeley.edu/) | Feb 2022 - May 2022

  * Worked with osmosis.zone on a solution to decrease impermanent loss for liquidity providers

  Teaching Assistant at the [**UC Berkeley EECS Department**](https://eecs.berkeley.edu/) for [CS 198-112, Introduction to Algorithmic Thinking](https://algorithmicthinking.github.io/#/) and [CS 198-099, Introduction to Full-Stack Development](https://fullstackdecal.com/) | Jun 2022 - Aug 2022

  * Founded and Ran Intro to Full-Stack Development, growing it to over 200 students per semester
  * Helped create content and run the Leetcode 101 course, preparing students for software engineering interviews

  Software Engineer at an [**anonymous Avalanche-based DeFi Startup**](https://www.avax.network/), with over $1 billion TVL at peak | Jun 2022 - Aug 2022

  Quantitative Strategies intern at [**Goldman Sachs**](https://www.goldmansachs.com/) in the New York City Office | Jun 2022 - Aug 2022

  * Implemented new risk controls and sped up NSFI risk calculations by ~95%

  Member of the board of directors for [**Carepath**](https://join.care-path.org/), a nonprofit connecting parents of children with mental disorders | Feb 2022 - May 2022

  Product manager at [**Launchparty**](https://www.launchpartyvc.com/), a startup aiming to empower student entrepreneurship | Feb 2022 - May 2022

  Frontend lead for [**Resource 19**](https://resource19.org/), a platform aiding hospitals in the fight against COVID-19 | Feb 2022 - May 2022
  
  * Mentioned in an article by VMWare CEO Pat Gelsinger
  * Mentioned in an article by the UC Berkeley EECS department
  * Confirmed the donation of 95,000+ masks and 6,000+ face shields, and 70+ PPE design uploads

  Founder and President of [**Cloud at California**](https://www.cloudatcal.club/) | Feb 2022 - May 2022
  
  Software Engineer at [**Hyperspec AI**](https://hyperspec.ai/) and the [**Netic Corporation**](https://www.netic.health/) | Feb 2022 - May 2022
  
  Research Assistant and Software Developer for the [**Northwestern University**](https://physics.northwestern.edu/index.html) high-energy physics department under the Geraci research group | Feb 2022 - May 2022
  
  * Aided in work towards the discovery of dark matter as part of the ARIADNE project

  Sales at [**Blue Raven Solar**](https://blueravensolar.com/) | Feb 2022 - May 2022
  `;

  return (
    <Box>
      <Text variant="subtitle" mb={4}>
        A Little About Me
      </Text>
      <UnorderedList ml={0} pl={0}>
        <Stack spacing={3.5}>
          <ReactMarkdown
            children={current}
            components={{
              li: ListItem,
              ul: ({ node, ...props }) => (
                <Stack spacing={1.5} pl={8} {...props} />
              ),
              a: ({ node, ...props }) => (
                <chakra.a
                  // target="_blank"
                  _hover={{ textDecoration: "underline" }}
                  {...props}
                />
              ),
            }}
          />
        </Stack>
      </UnorderedList>

      <Text variant="subtitle" mb={4} mt={8}>
        Previous Involvements
      </Text>
      <UnorderedList ml={0} pl={0}>
        <Stack spacing={3.5} flex={4}>
          <ReactMarkdown
            children={previous}
            components={{
              li: ListItem,
              ul: ({ node, ...props }) => (
                <Stack spacing={1.5} pl={8} {...props} />
              ),
              a: ({ node, ...props }) => (
                <chakra.a
                  target="_blank"
                  _hover={{ textDecoration: "underline" }}
                  {...props}
                />
              ),
            }}
          />
        </Stack>
      </UnorderedList>
      <Text variant="subtitle" mb={4} mt={8}>
        Technical Skills
      </Text>
      <Stack spacing={3.5}>
        <Text w={["100%", "80%", "60%"]}>
          Full-Stack Software Engineer, Data Scientist, and Problem-Solver
          trying to get to the level of an AI and Machine Learning Researcher.
          Also focused on effectively leading technical teams (in addition to
          non-technical teams).
        </Text>
        <Text>
          <strong>Specializations:</strong> AI/ML, Data Science, Web and Mobile
          Development, DeFi Development
        </Text>
        <Text>
          <strong>General Programming:</strong> Python, Java, C, JavaScript,
          Solidity
        </Text>
        <Text>
          <strong>Frontend Development:</strong> HTML/CSS, JavaScript,
          TypeScript, React.js, React Native, Next.js
        </Text>
        <Text>
          <strong>Backend Development:</strong> Node.js, Express, MongoDB,
          MySQL, REST APIs, AWS
        </Text>
        <Text>
          <strong>DevOps:</strong> AWS, Git, Agile/Scrum, Systems Architecting
        </Text>
        <Text>
          <strong>Design:</strong> Figma
        </Text>
      </Stack>

      <Text variant="subtitle" mb={4} mt={8} id="coursework">
        Relevant Coursework
      </Text>
      <Text variant="header" mb={4}>
        <strong>Computer Science and Electrical Engineering</strong>
      </Text>
      <Stack spacing={3.5}>
        {EECS.map((course) => (
          <Text>{course}</Text>
        ))}
      </Stack>
      <Text variant="header" mt={5} mb={4}>
        <strong>Statistics, Math, and Physics</strong>
      </Text>
      <Stack spacing={3.5}>
        {statistics.map((course) => (
          <Text>{course}</Text>
        ))}
      </Stack>
      <Text variant="header" mt={5} mb={4}>
        <strong>Business Administration</strong>
      </Text>
      <Stack spacing={3.5}>
        {business.map((course) => (
          <Text>{course}</Text>
        ))}
      </Stack>
    </Box>
  );
};

export default Resume;
