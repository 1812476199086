import React, { useEffect } from "react";
import { Box, Stack, SimpleGrid, Divider, Text } from "@chakra-ui/react";
import ProjectPreview from "../components/ProjectPreview";
import Highlight from "../components/Highlight";
import projects from "../data/projects";
import highlights from "../data/highlights";

const MyWork = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const years = [...new Set(projects.map((project) => project.year))];

  return (
    <Box>
      <Text variant="subtitle" mb={5}>
        Highlights
      </Text>
      <Stack spacing={10}>
        {highlights.map(({ name, summary, preview, key }) => (
          <Highlight
            name={name}
            description={summary}
            image={preview}
            path={key}
          />
        ))}
      </Stack>
      <Divider mt={[10, 8]} mb={6} />
      <Stack spacing={8}>
        {years.map((year) => (
          <Box>
            <Text variant="subtitle" mb={5}>
              {year}
            </Text>
            <SimpleGrid
              columns={[1, null, 2, null, 3]}
              spacingX={20}
              spacingY={12}
            >
              {projects
                .filter((project) => project.year === year)
                .map(({ name, summary, preview, key }) => (
                  <ProjectPreview
                    name={name}
                    description={summary}
                    image={preview}
                    path={key}
                  />
                ))}
            </SimpleGrid>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default MyWork;
