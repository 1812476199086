import { extendTheme, Stack } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    blue: "#255A94",
  },
  styles: {
    global: {
      p: {
        fontFamily: "montserrat",
        fontSize: "md",
        fontWeight: "normal",
      },
      ul: {
        pl: 8,
      },
      li: {
        fontFamily: "montserrat",
        fontSize: "md",
        fontWeight: "normal",
      },
    },
  },
  components: {
    Stack: {
      baseStyle: {
        spacing: 3.5,
      },
    },
    Text: {
      baseStyle: {
        fontFamily: "montserrat",
        fontSize: "md",
        fontWeight: "normal",
      },
      variants: {
        name: {
          fontFamily: "raleway",
          fontSize: "5xl",
          fontWeight: "bold",
        },
        logo: {
          fontFamily: "raleway",
          fontSize: "lg",
          fontWeight: "semibold",
          letterSpacing: "0.08em",
          cursor: "pointer",
        },
        nav: {
          fontFamily: "raleway",
          fontSize: "md",
          fontWeight: "medium",
          color: "grey",
        },
        active: { fontFamily: "raleway", fontSize: "lg", fontWeight: "bold" },
        subtitle: {
          fontFamily: "raleway",
          fontSize: "5xl",
          fontWeight: "normal",
        },
        section: {
          fontFamily: "raleway",
          fontSize: "4xl",
          fontWeight: "normal",
        },
        header: {
          fontFamily: "raleway",
          fontSize: "xl",
          fontWeight: "normal",
          textDecoration: "underline",
        },
        project: {
          fontFamily: "raleway",
          fontSize: "xl",
          fontWeight: "semibold",
        },
      },
    },
  },
});

export default theme;
