import BeachBoys from "../assets/music/beach-boys.png";
import Kpop from "../assets/music/kpop.png";
import Science from "../assets/music/science.png";
import Pride from "../assets/music/pride.png";
import GirlPower from "../assets/music/girl-power.png";

const music = [
  {
    preview: BeachBoys,
    title: "Cal Band Beach Boys Show",
    length: "6:59",
    link: "https://www.youtube.com/watch?v=0xCCUUzUvZ0&list=PLb2JEw8EpnaafL3mSJ00JsMaFb8nSBrhE&index=4",
    type: "performance",
  },
  {
    preview: Kpop,
    title: "Cal Band K-Pop Show",
    length: "7:25",
    link: "https://www.youtube.com/watch?v=IrahBMhjBCs&list=PLb2JEw8EpnaafL3mSJ00JsMaFb8nSBrhE&index=5",
    type: "performance",
  },
  {
    preview: Science,
    title: "Cal Band Science Show",
    length: "6:42",
    link: "https://www.youtube.com/watch?v=07Ka192BQGY&list=PLb2JEw8EpnaawU7XaWfr8jqy-QTiwY6RC&index=9",
    type: "performance",
  },
  {
    preview: Pride,
    title: "Cal Band Pride Show",
    length: "8:07",
    link: "https://www.youtube.com/watch?v=LDkGGVX-5oY&list=PLb2JEw8EpnaawU7XaWfr8jqy-QTiwY6RC&index=7",
    type: "performance",
  },
  {
    preview: GirlPower,
    title: "Cal Band Girl Power Show",
    length: "7:22",
    link: "https://www.youtube.com/watch?v=5vQfTZ32tmc&list=PLb2JEw8EpnaawU7XaWfr8jqy-QTiwY6RC&index=8",
    type: "performance",
  },
  // {
  //   preview: null,
  //   title: "Hunter",
  //   length: "1:02",
  //   link: "https://drive.google.com/file/d/1D6qonRErrHiVZ3mID3VeoSBZDLFvn2XI/view?usp=sharing",
  //   type: "cadence",
  // },
  // {
  //   preview: null,
  //   title: "Slam",
  //   length: "1:02",
  //   link: "https://drive.google.com/file/d/1CWng9_HzIIrAwT_BQzZwbyFJOVHk7TQp/view?usp=sharing",
  //   type: "cadence",
  // },
  // {
  //   preview: null,
  //   title: "ScrewDriver",
  //   length: "1:02",
  //   link: "https://drive.google.com/file/d/1mxze_EyUkbE6nwiw3rT-HbIMkauEq1rR/view?usp=sharing",
  //   type: "cadence",
  // },
  // {
  //   preview: null,
  //   title: "test",
  //   length: "1:02",
  //   link: "https://drive.google.com/file/d/1D6qonRErrHiVZ3mID3VeoSBZDLFvn2XI/view?usp=sharing",
  //   type: "cadence",
  // },
  // {
  //   preview: null,
  //   title: "test",
  //   length: "1:02",
  //   link: "",
  //   type: "arrangement",
  // },
];

export default music;
