import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Flex, Stack, Text, chakra } from "@chakra-ui/react";

import Headshot from "../assets/headshot.JPG";
import HeadshotSquare from "../assets/headshot-square.JPG";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex direction={["column-reverse", null, "row"]}>
      <Box flex={[1, 1, 4]} pr={6}>
        <Text variant="name" mb={3}>
          Samarth Goel
        </Text>
        <Stack spacing={3}>
          <Text>Hi there, nice to meet you!</Text>
          <Text>
            My name is Samarth Goel, a startup enthusiast, musician, athlete,
            aspiring chef, and student at UC Berkeley. Welcome to my website!
          </Text>
          <Text>
            I'm interested in logic, connection, and the abstract concept of
            humanity. I often find myself pointing out and taking joy in the
            parallels between physics, statistics, computer science, economics,
            and intuition.
          </Text>
          <Text>
            I love reasoning about unreasonable philisophical conundrum,
            conducting behavioral analysis on market environments, and learning
            as much as humanly possible.
          </Text>
          <Text>
            My professional interests lie in handling the business side, machine
            learning/data science, software engineering, and the inner workings
            of technology companies from a business perspective, ranging from
            startups to tech giants. If you want to find out more, check out my{" "}
            <Link to="/resume">
              <chakra.span
                color="blue"
                _hover={{ textDecoration: "underline" }}
              >
                resume
              </chakra.span>
            </Link>{" "}
            or my{" "}
            <Link to="/work">
              <chakra.span
                color="blue"
                _hover={{ textDecoration: "underline" }}
              >
                previous work
              </chakra.span>
            </Link>
            .
          </Text>
          <Text>
            Interested in working together, have feedback for my website, or
            just want to talk? Feel free to email me at sgoel9@berkeley.edu.
          </Text>
        </Stack>
      </Box>
      <Box flex={[1, null, 2, 2, 3]} position="relative" h="100%">
        <Flex pr={["50%", "65%"]} mb={4} display={["flex", null, "none"]}>
          <img
            src={HeadshotSquare}
            alt="Samarth Goel"
            style={{ borderRadius: ".25rem" }}
          />
        </Flex>
        <Flex
          pr={[0, null, null, null, "25%"]}
          pl={[0, 0, 4, "15%"]}
          display={["none", null, "flex"]}
          position="absolute"
        >
          <img
            src={Headshot}
            alt="Samarth Goel"
            style={{ borderRadius: ".25rem" }}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default Home;
