import Bair from "../assets/projects/bair.png";
import HRT from "../assets/projects/hrt.png";
import WDB from "../assets/projects/wdb.png";
import Resource19 from "../assets/projects/resource19.png";
import FullStackDecal from "../assets/projects/fullstackdecal.png";

const highlights = [
  {
    name: "Berkeley AI Research (BAIR)",
    summary:
      "My first time in academia. Published thesis centered around analyzing weakness in embedding models in unclean data environments, and improving text similarity measurements to be robust and aligned with human understanding.",
    preview: Bair,
    key: "bair",
  },
  {
    name: "Hudson River Trading",
    summary:
      "I interned at HRT this summer as an Algorithm Development/Quantitatve Research Intern on the High-Frequency Team and the ETF Arbitrage Team.",
    preview: HRT,
    key: "hrt",
  },
  {
    name: "Web Development at Berkeley",
    summary:
      "I founded WDB in Fall 2020. I created the industry, education, and bootcamp initiatives, including founding and running an official UC Berkeley course on Full-Stack Development.",
    preview: WDB,
    key: "wdb",
  },
];

export default highlights;
