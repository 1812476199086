import { FaLinkedin, FaGithub, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const social_icon_style = { size: "2em" };
const socials = [
  {
    icon: <FaLinkedin {...social_icon_style} />,
    name: "Linkedin",
    link: "https://www.linkedin.com/in/samarth-goel-1047b8187/",
  },
  {
    icon: <FaGithub {...social_icon_style} />,
    name: "Github",
    link: "https://www.github.com/sgoel97",
  },
  {
    icon: <FaInstagram {...social_icon_style} />,
    name: "Instagram",
    link: "https://www.instagram.com/samarth.go/",
  },
  {
    icon: <FaXTwitter {...social_icon_style} />,
    name: "X",
    link: "https://twitter.com/firestorm776",
  },
];

export default socials;
