//2024
import Bair from "../assets/projects/bair.png";
import Resumeag from "../assets/projects/resumeag.png";
import LatchBio from "../assets/projects/latchbio.png";
import LMOrch from "../assets/projects/lmorch.png";
import BeliMatch from "../assets/projects/belimatch.png";

//2023
import HRT from "../assets/projects/hrt.png";
import Restaurant from "../assets/projects/restaurant.png";
import PIDAIL from "../assets/projects/pidail.png";

// 2022
import IMC from "../assets/projects/imc.png";
import PokerAtBerkeley from "../assets/projects/pokeratberkeley.png";
import Avalanche from "../assets/projects/avalanche.png";

// 2021
import FullStackDecal from "../assets/projects/fullstackdecal.png";
import Launchparty from "../assets/projects/launchparty.png";
import TradersAtBerkeley from "../assets/projects/tradersatberkeley.png";
import Kalenda from "../assets/projects/kalenda.png";
import GoldmanSachs from "../assets/projects/goldmansachs.png";
import GoAhead from "../assets/projects/goahead.png";
import CivHacks from "../assets/projects/civhacks.png";
import CS191 from "../assets/projects/cs191.png";
import Leetcode101 from "../assets/projects/leetcode101.png";
import Mira from "../assets/projects/mira.png";
import ProjectAEI from "../assets/projects/projectaei.png";
import UDoItLegal from "../assets/projects/udoitlegal.png";

// 2020
import WDB from "../assets/projects/wdb.png";
import Resource19 from "../assets/projects/resource19.png";
import Barracuda from "../assets/projects/barracuda.png";
import BearBonesStorage from "../assets/projects/bearbonesstorage.png";
import Carepath from "../assets/projects/carepath.png";
import CloudAtCal from "../assets/projects/cloudatcal.png";
import CS170 from "../assets/projects/cs170.png";
import FormulaElectric from "../assets/projects/formulaelectric.png";
import Netic from "../assets/projects/netic.png";

// 2019
import HyperspecAI from "../assets/projects/hyperspecai.png";
import Northwestern from "../assets/projects/northwestern.png";

const projects = [
  {
    name: "Berkeley AI Research (BAIR)",
    summary: "LLM Research in Embedding Models and Similarity Measurement",
    preview: Bair,
    year: 2024,
    key: "bair",
  },
  {
    name: "Latch Bio",
    summary: "Workflow Orchstration Framework Project",
    preview: LatchBio,
    year: 2024,
    key: "latch-bio",
  },
  {
    name: "LM-Orch",
    summary: "Language Model Evaluation and Embedding Orchstration",
    preview: LMOrch,
    year: 2024,
    key: "lm-orch",
  },
  {
    name: "ResumeAG",
    summary: "Query Resumes with Natural Language",
    preview: Resumeag,
    year: 2024,
    key: "resume-ag",
  },
  {
    name: "BeliMatch",
    summary: "Datamatch meets Beli",
    preview: BeliMatch,
    year: 2024,
    key: "belimatch",
  },
  {
    name: "Hudson River Trading",
    summary: "Algorithm Development Internship",
    preview: HRT,
    year: 2023,
    key: "hrt",
  },
  {
    name: "Restaurant-Gen",
    summary: "Data Generator for Food",
    preview: Restaurant,
    year: 2023,
    key: "restaurant",
  },
  {
    name: "PIDAIL",
    summary: "Pruning Inconsistent RL Demonstrations",
    preview: PIDAIL,
    year: 2023,
    key: "pidail",
  },
  {
    name: "IMC Trading",
    summary: "Quant Trading Internship on Equity Options Desk",
    preview: IMC,
    year: 2022,
    key: "imc-trading",
  },
  {
    name: "Anonymous DeFi Startup",
    summary: "Avalanche-Based DeFi Lending Platform",
    preview: Avalanche,
    year: 2022,
    key: "defi",
  },
  {
    name: "Poker at Berkeley",
    summary: "UC Berkeley's Premier Poker Organization",
    preview: PokerAtBerkeley,
    year: 2022,
    key: "poker-at-berkeley",
  },
  {
    name: "Full Stack DeCal",
    summary: "Teaching Industry-Level Full-Stack Web Development",
    preview: FullStackDecal,
    year: 2021,
    key: "full-stack-decal",
  },
  {
    name: "Launchparty",
    summary: "Empowering Student Entreprenuership",
    preview: Launchparty,
    year: 2021,
    key: "launchparty",
  },
  {
    name: "Traders At Berkeley",
    summary: "UC Berkeley's Premier Quant Finance Community",
    preview: TradersAtBerkeley,
    year: 2021,
    key: "traders-at-berkeley",
  },
  // {
  //   name: "Kalenda",
  //   summary: "Revamping how Teams Schedule Meetings",
  //   description: {},
  //   preview: Kalenda,
  //   year: 2021,
  //   key: "kalenda",
  //   images: [],
  //   intro: "coming soon!",
  // },
  {
    name: "Goldman Sachs",
    summary: "Quantitative Strategies Intern in Controllers Division",
    preview: GoldmanSachs,
    year: 2021,
    key: "goldman-sachs",
  },
  // {
  //   name: "GoAhead Ventures",
  //   summary: "Branding and Website Development for VC firm",
  //   description: {},
  //   preview: GoAhead,
  //   year: 2021,
  //   key: "goahead",
  //   images: [],
  //   intro: "coming soon!",
  // },
  {
    name: "CivHacks",
    summary: "Civics Focused Hackathon",
    preview: CivHacks,
    year: 2021,
    key: "civhacks",
  },
  {
    name: "Algorithmic Thinking DeCal",
    summary: "Helping Students Ace their Technical Interviews",
    preview: Leetcode101,
    year: 2021,
    key: "leetcode101",
  },
  {
    name: "Mira",
    summary: "Teaching Gen Z Financial Literacy",
    preview: Mira,
    year: 2021,
    key: "mira",
  },
  {
    name: "Project AEI",
    summary: "Detecting Police Brutaility through Body Cams",
    preview: ProjectAEI,
    year: 2021,
    key: "project-aei",
  },
  {
    name: "U-Do-It Legal",
    summary: "Making Legal Processes Easier for All",
    preview: UDoItLegal,
    year: 2021,
    key: "u-do-it-legal",
  },
  {
    name: "Web Development at Berkeley",
    summary: "UC Berkeley's Premier Web Development and Design Organization",
    preview: WDB,
    year: 2020,
    key: "wdb",
  },
  // {
  //   name: "Quantum Annealing TSP",
  //   summary: "Applying Quantum Algorithms to NP-Hard Problems",
  //   preview: CS191,
  //   year: 2020,
  //   key: "191",
  // },
  // {
  //   name: "NP-Hard Graph Partitioning",
  //   summary:
  //     "Using a Variety of Algorithms to Maximize Utility in NP-Hard Environment",
  //   preview: CS170,
  //   year: 2020,
  //   key: "170",
  // },
  {
    name: "Formula Electric at Berkeley",
    summary: "Branding and Site Development for Electric Car Build Team",
    preview: FormulaElectric,
    year: 2020,
    key: "formula-electric",
  },
  {
    name: "Netic Health",
    summary: "Automating Physical Therapy",
    preview: Netic,
    year: 2020,
    key: "netic",
  },
  {
    name: "Resource 19",
    summary: "Enabling Makers to Help Hospitals Fight COVID-19",
    preview: Resource19,
    year: 2020,
    key: "resource-19",
  },
  {
    name: "Barracuda Technologies",
    summary: "Branding and Site Development for Textile Research Company",
    preview: Barracuda,
    year: 2020,
    key: "barracuda",
  },
  {
    name: "Bear Bones Storage",
    summary: "Unlimited Lossless Storage with Google Photos",
    preview: BearBonesStorage,
    year: 2020,
    key: "bear-bones-storage",
  },
  {
    name: "Carepath",
    summary: "Creating a Community for Parents of Children with OCD",
    preview: Carepath,
    year: 2020,
    key: "carepath",
  },
  {
    name: "Cloud At Cal",
    summary: "UC Berkeley's Premier Cloud Computing and Research Organization",
    preview: CloudAtCal,
    year: 2020,
    key: "cloud-at-cal",
  },
  {
    name: "HyperspecAI",
    summary: "Developed Authentication Scheme for Self-Driving Vehicle SDK",
    preview: HyperspecAI,
    year: 2019,
    key: "hyperspecai",
  },
  {
    name: "Northwestern High-Energy Physics Lab",
    summary: "Wrote Python Scripts to Aid in Discovery of Dark Matter",
    preview: Northwestern,
    year: 2019,
    key: "northwestern",
  },
];

export default projects;
