import React, { useEffect } from "react";
import { Box, Stack, SimpleGrid, Text } from "@chakra-ui/react";
import Score from "../components/Score";
import music from "../data/music";

const Music = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const performances = music.filter((piece) => piece.type == "performance");
  const cadences = music.filter((piece) => piece.type == "cadence");
  const arrangements = music.filter((piece) => piece.type == "arrangement");

  const sections = [
    { title: "Performances", data: performances },
    // { title: "Drumline Cadences", data: cadences },
    // { title: "Marching Band Arrangements", data: arrangements },
  ];

  return (
    <Box>
      <Stack spacing={8}>
        {sections.map(({ title, data }) => (
          <Box>
            <Text variant="subtitle" mb={5}>
              {title}
            </Text>
            <SimpleGrid
              columns={[1, null, 2, null, 3]}
              spacingX={20}
              spacingY={12}
            >
              {data.map(({ preview, title, length, link }) => (
                <Score
                  preview={preview}
                  title={title}
                  length={length}
                  link={link}
                />
              ))}
            </SimpleGrid>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default Music;
