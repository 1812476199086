const EECS = [
  "CS 285: Reinforcement Learning",
  "CS 281A: Statistical Learning Theory",
  "CS 182: Neural Networks",
  "CS 189: Machine Learning",
  "CS 188: Artificial Intelligence",
  "CS 170: Algorithms",
  "CS 191: Quantum Computing",
  "CS 194: Decentralized Finance",
  "CS 198: Algorithmic Thinking",
  "Data 100: Principles and Techniques of Data Science",
  "EECS 127: Optimization Models",
  "EECS 70: Discrete Mathematics and Probability Theory",
  "CS 61C: Computer Architecture",
  "CS 61B: Data Structures",
  "CS 61A: Structure and Interpretation of Computer Programs",
  "EECS 16A: Designing Information Devices and Systems I",
  "EECS 16B: Designing Information Devices and Systems II",
  "Data 8: Foundations of Data Science",
];

const statistics = [
  "Math 191: Putnam Seminar",
  "Math 272: Market Design and Theory",
  "Stat 155: Game Theory",
  "Stat 151A: Linear Models",
  "Stat 150: Stochastic Processes",
  "Data 140: Probability for Data Science",
  "Stat 135: Concepts of Statistics",
  "STAT 198: Introduction to Quantitative Finance",
  "Physics 137A: Quantum Mechanics",
  "MATH 54: Linear Algebra and Differential Equations",
];

const business = [
  "UGBA 196: Leading With Impact",
  "UGBA 180: Real Estate",
  "UGBA 107: Business Ethics",
  "UGBA 106: Marketing",
  "UGBA 105: Leadership",
  "UGBA 104: Business Analytics",
  "UGBA 103: Finance",
  "UGBA 102B: Managerial Accounting",
  "UGBA 102A: Financial Accounting",
  "UGBA 101B: Macroeconomics",
  "UGBA 101A: Microeconomics",
  "UGBA 100: Business Communication",
  "UGBA 10: Data and Decisions",
  "UGBA 10: Principles of Business",
  "Econ 1: Introduction to Economics",
];

export { EECS, business, statistics };
