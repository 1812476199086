import { Link } from "react-router-dom";
import { Box, Flex, Text } from "@chakra-ui/react";

const ProjectPreview = ({ name, description, image, path }) => (
  <Link to={`work/${path}`}>
    <Flex
      direction="column"
      transition=".15s ease"
      _hover={{
        transform: "translateY(-.2rem)",
      }}
    >
      <Box maxW={56} borderRadius="2xl">
        <img src={image} alt={name} />
      </Box>
      <Text mt={4} variant="project">
        {name}
      </Text>
      <Text mt={2}>{description}</Text>
    </Flex>
  </Link>
);

export default ProjectPreview;
