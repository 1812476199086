import { Box, Text, Flex } from "@chakra-ui/react";

const Score = ({ preview, title, length, link }) => (
  <a href={link} target="_blank">
    <Flex
      direction="column"
      transition=".15s ease"
      _hover={{
        transform: "translateY(-.2rem)",
      }}
    >
      <Box maxW={56} borderRadius="lg" overflow="hidden">
        <img src={preview} alt={title} style={{ height: "100%" }} />
      </Box>
      <Text mt={4} variant="project" fontSize="lg">
        {title}
      </Text>
      <Text mt={1}>{length}</Text>
    </Flex>
  </a>
);

export default Score;
