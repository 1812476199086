import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Text } from "@chakra-ui/react";
import Bio from "../components/Bio";
import posts from "../data/blog-data.json";

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let { category } = useParams();

  let selectedPosts = posts;

  if (category) {
    selectedPosts = posts.filter((post) => post.category === category);
  }

  selectedPosts.sort((a, b) => {
    return Date.parse(b.date) - Date.parse(a.date);
  });
  console.log(selectedPosts);

  const categoryTitles = {
    quant: "Quant Finance Articles",
    travel: "Travel and Location Guides",
    software: "Software and Crypto Articles",
    misc: "Misc. Thoughts and Posts",
  };

  let title = "All Posts";
  if (category) {
    title = categoryTitles[category];
  }

  return (
    <Box px={[0, null, null, 4, 8]}>
      <Box>
        <Text fontSize={["4xl", "5xl"]} fontWeight="bold" mb={3}>
          Samarth's Blog
        </Text>
        <Text mb={10}>
          I like numbers and people. I write about both, but mostly about
          numbers.
        </Text>
      </Box>

      {/* {!category && <Bio />} */}
      <Bio />

      <Text fontSize="4xl" fontWeight="bold" mt={16}>
        {title}
      </Text>
      {selectedPosts.map(({ title, date, description, id }) => (
        <Box key={title}>
          <Link to={`/blog/post/${id}`}>
            <Text fontSize="3xl" fontWeight="bold" color="blue" mt={6}>
              {title}
            </Text>
          </Link>
          <Text fontSize="xs" mt={1} mb={4}>
            {date}
          </Text>
          <Text>{description}</Text>
        </Box>
      ))}
    </Box>
  );
};

export default Blog;
