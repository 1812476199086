import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import {
  Box,
  UnorderedList,
  ListItem,
  Stack,
  Text,
  chakra,
} from "@chakra-ui/react";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const professional = `
  - [My Resume Template](https://docs.google.com/document/d/148Ad8l0MkVTAP32JlLGYae27r5G_59URGUUWXpTVLFQ/edit?usp=sharing)
  - [Course I Created on Full-Stack Development](https://fullstackdecal.com/)
  - [My Guide to Chicago](https://docs.google.com/spreadsheets/d/1jke16brYuEZCHnmdJR5FT3dDzBRIfsVTH7B60Gb5w8s/edit?usp=sharing)
  - [My Blog](https://blog.samarthgoel.com/)
  `;

  const personal = `
  - tbd
  `;

  return (
    <>
      <Box>
        <Text variant="subtitle" mb={2}>
          Resources
        </Text>
        <UnorderedList ml={0} pl={0}>
          <Stack spacing={3.5}>
            <ReactMarkdown
              children={professional}
              components={{
                li: ListItem,
                ul: ({ node, ...props }) => (
                  <Stack spacing={1.5} pl={8} {...props} />
                ),
                a: ({ node, ...props }) => (
                  <chakra.a target="_blank" color="blue" {...props} />
                ),
              }}
            />
          </Stack>
        </UnorderedList>
        {/* <Text variant="subtitle" mb={5} mt={8}>
          Personal
        </Text>
        <UnorderedList ml={0} pl={0}>
          <Stack spacing={3.5}>
            <ReactMarkdown
              children={personal}
              components={{
                li: ListItem,
                ul: ({ node, ...props }) => (
                  <Stack spacing={1.5} pl={8} {...props} />
                ),
                a: ({ node, ...props }) => (
                  <chakra.a target="_blank" color="blue" {...props} />
                ),
              }}
            />
          </Stack>
        </UnorderedList> */}
      </Box>
    </>
  );
};

export default Home;
