import { Switch, Route, Link, useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  Stack,
  Text,
  useDisclosure,
  Drawer,
  DrawerContent,
  CloseButton,
  IconButton,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import Home from "./pages/Home";
import MyWork from "./pages/MyWork";
import Project from "./pages/Project";
import Resume from "./pages/Resume";
import Music from "./pages/Music";
import Resources from "./pages/Resources";
import Blog from "./pages/Blog";
import Post from "./pages/Post";
import Layout from "./components/Layout";
import projects from "./data/projects";
import socials from "./data/socials";
import "./css/app.css";

function App() {
  const routes = [
    { name: "Home", route: "/" },
    { name: "My Work", route: "/work" },
    { name: "Resume", route: "/resume" },
    { name: "Music", route: "/music" },
    { name: "Resources", route: "/resources" },
    { name: "Blog", route: "/blog" },
    { name: "Quant", route: "/blog/quant" },
    { name: "Travel", route: "/blog/travel" },
    { name: "Swe/Crypto", route: "/blog/software" },
    { name: "Misc.", route: "/blog/misc" },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();

  let location = useLocation();
  let path = location.pathname;
  if (path.includes("/work/")) {
    const name = projects.filter((project) => path.includes(project.key))[0]
      .name;
    routes.splice(2, 0, { name: name, route: path });
  }

  const checkActive = (route, path) => {
    return route === path || (path.includes("post/") && route === "/blog");
  };

  const taglines = [
    // "Chaotic Harmony.",
    // "Building da Web.",
    // "History Enthusiast.",
    // "🚀 Builder.",
    "Builder and Entrepreneur",
  ];
  const tagline = taglines[Math.floor(Math.random() * taglines.length)];

  return (
    <Layout>
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
        display={["flex", null, "none"]}
      >
        <DrawerContent>
          <Box
            bg="white"
            w={["full", 60]}
            position="fixed"
            h="full"
            px={[4, 6, 8]}
          >
            <Flex h={20} pt={5} align="flex-start" justify="space-between">
              <Link to="/">
                <Text variant="logo">Samarth Goel</Text>
                <Text variant="logo" fontSize="xs" fontWeight="normal">
                  {tagline}
                </Text>
              </Link>
              <CloseButton onClick={onClose} />
            </Flex>
            <Stack direction="column" spacing={3}>
              {routes.map(({ name, route }) => (
                <Link to={route}>
                  <Text
                    variant={checkActive(route, path) ? "active" : "nav"}
                    _hover={{
                      fontWeight: checkActive(route, path)
                        ? "bold"
                        : "semibold",
                    }}
                    _after={{
                      backgroundColor: checkActive(route, path)
                        ? "black"
                        : "grey",
                    }}
                    py={0.25}
                    ml={
                      route.includes("/work/") || route.includes("/blog/")
                        ? 4
                        : 0
                    }
                    className="nav-item"
                    style={{
                      width: "fit-content",
                    }}
                    onClick={onClose}
                  >
                    {name}
                  </Text>
                </Link>
              ))}
            </Stack>
          </Box>
        </DrawerContent>
      </Drawer>

      {/* Mobile Nav */}
      <Flex
        position="fixed"
        bg="white"
        top={0}
        right={0}
        left={0}
        h={16}
        justify="space-between"
        align="center"
        px={[4, 6, 8]}
        zIndex={1000}
        display={["flex", null, "none"]}
        borderBottom="1px solid black"
      >
        <Link to="/">
          <Text variant="logo">Samarth Goel</Text>
        </Link>
        <IconButton
          variant="outline"
          onClick={onOpen}
          aria-label="open menu"
          icon={<FiMenu />}
        />
      </Flex>

      {/* Desktop Nav */}
      <Flex
        h="100vh"
        flex={1}
        justify="center"
        direction="column"
        position="relative"
        display={["none", null, "flex"]}
      >
        <Box position="fixed" top={20} left={[0, 0, 12, 28]}>
          <Link to="/">
            <Text variant="logo" className="nav-item">
              Samarth Goel
              <br />
            </Text>
          </Link>
          <Text variant="logo" fontSize="xs" fontWeight="normal" mt={1}>
            {tagline}
          </Text>
        </Box>
        <Stack position="fixed" overflowWrap="break-word" w="20%" pr={12}>
          {routes.map(({ name, route }) => (
            <Link to={route}>
              <Text
                variant={checkActive(route, path) ? "active" : "nav"}
                _hover={{
                  fontWeight: checkActive(route, path) ? "bold" : "semibold",
                }}
                _after={{
                  backgroundColor: checkActive(route, path) ? "black" : "grey",
                }}
                py={0.25}
                ml={
                  route.includes("/work/") || route.includes("/blog/") ? 4 : 0
                }
                className="nav-item"
                style={{
                  width: "fit-content",
                }}
              >
                {name}
              </Text>
            </Link>
          ))}
          <Flex gap={3.5} pt={2.5}>
            {socials.map(({ icon, link }) => (
              <a href={link} target="_blank" rel="noreferrer">
                {icon}
              </a>
            ))}
          </Flex>
          <Text fontSize="sm" position="fixed" bottom={4} left={[0, 0, 12, 28]}>
            © Samarth Goel 2023
          </Text>
        </Stack>
      </Flex>

      {/* Routes */}
      <Box minH="100%" h="fit-content" flex={4} zIndex={1}>
        <Flex
          minH="100vh"
          h="100%"
          align="center"
          justifyContent="space-between"
          py={[20, null, 12]}
          pb={[1, null, 12]}
          my={1}
          ml={[0, 0, 2, 0]}
          pl={[0, null, 12, 8, 4]}
          direction={["column", null, "row"]}
        >
          <Switch>
            <Route path="/resume">
              <Resume />
            </Route>
            <Route path="/work/:id">
              <Project />
            </Route>
            <Route path="/work">
              <MyWork />
            </Route>
            <Route path="/music">
              <Music />
            </Route>
            <Route path="/resources">
              <Resources />
            </Route>
            <Route path="/blog/post/:id">
              <Post />
            </Route>
            <Route path="/blog/:category?">
              <Blog />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
          <Box mt={12} display={["block", null, "none"]}>
            <Flex justify="center" gap={3.5} mb={2.5}>
              {socials.map(({ icon, link }) => (
                <a href={link} target="_blank" rel="noreferrer">
                  {icon}
                </a>
              ))}
            </Flex>
            <Text fontSize="sm" align="center" pb={4}>
              © Samarth Goel 2023
            </Text>
          </Box>
        </Flex>
      </Box>
    </Layout>
  );
}

export default App;
