import { Flex, Box, Text } from "@chakra-ui/react";
import Goku from "../assets/goku.png";

const Bio = () => (
  <Flex align="center">
    <Box h={16} w={16} minW={16} mr={[4, 6]}>
      <img src={Goku} alt="Samarth" style={{ borderRadius: "1000px" }} />
    </Box>
    <Box>
      <Text mb={1}>
        Written by <strong>Samarth</strong>, a builder, problem-solver, and
        amateur gymnast.
      </Text>
      <Text color="blue">
        <a href="https://samarthgoel.com/">samarthgoel.com</a>
      </Text>
    </Box>
  </Flex>
);

export default Bio;
