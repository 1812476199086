import { Link } from "react-router-dom";
import { Box, Flex, Text } from "@chakra-ui/react";

const Highlight = ({ name, description, image, path }) => (
  <Flex direction={["column", null, null, null, "row"]}>
    <Box flex={2} maxW={56} borderRadius="2xl" mb={[4, null, null, null, 0]}>
      <img src={image} alt={name} width="100%" />
    </Box>

    <Flex flex={7} direction="column" ml={[0, null, null, null, 9]}>
      <Text mb={4} variant="header">
        {name}
      </Text>
      <Text mb={[3, null, 2]}>{description}</Text>
      <Link to={`work/${path}`} className="nav-item">
        <Text fontWeight="semibold">{"Learn More >"}</Text>
      </Link>
    </Flex>
  </Flex>
);

export default Highlight;
