import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import {
  Box,
  Text,
  OrderedList,
  ListItem,
  chakra,
  UnorderedList,
} from "@chakra-ui/react";
import projects from "../data/projects";

const Project = () => {
  let { id } = useParams();
  const [content, setContent] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const curr = projects.filter((project) => project.key === id)[0];

    const mdPath = require(`../work/${curr.key}.md`);
    fetch(mdPath)
      .then((response) => response.text())
      .then((response) => setContent(response));
  }, []);

  return (
    <>
      <Box>
        <ReactMarkdown
          children={content}
          components={{
            h1: ({ node, ...props }) => (
              <Text variant="subtitle" mb={2} {...props} />
            ),
            h4: ({ node, ...props }) => <Text mb={2} {...props} />,
            h5: ({ node, ...props }) => (
              <Text
                color="blue"
                _hover={{ textDecoration: "underline" }}
                mb={4}
                {...props}
              />
            ),
            h6: ({ node, ...props }) => <Text mb={8} {...props} />,
            li: ListItem,
            ol: ({ node, ...props }) => (
              <OrderedList spacing={3} my={3} {...props} />
            ),
            ul: ({ node, ...props }) => (
              <UnorderedList spacing={1.5} pl={8} {...props} />
            ),
            a: ({ node, ...props }) => (
              <chakra.a
                // target="_blank"
                _hover={{ textDecoration: "underline" }}
                {...props}
              />
            ),
          }}
        />
      </Box>
    </>
  );
};

export default Project;
