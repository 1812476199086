import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import {
  Box,
  Text,
  ListItem,
  chakra,
  UnorderedList,
  Divider,
} from "@chakra-ui/react";
import Bio from "../components/Bio";

const Post = () => {
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let [readable, setReadable] = React.useState({ md: "" });

  let file = `https://raw.githubusercontent.com/sgoel97/blog/main/content/blog/${id}/index.md`;

  useEffect(() => {
    fetch(file)
      .then((res) => res.text())
      .then((md) => {
        let lines = md.split("\n");
        lines.splice(0, 1);
        lines.splice(2, 4);
        lines[0] = lines[0].replace("title:", "#");
        lines[1] = lines[1].replace("date:", "#####").replace(/"/g, "");
        lines.splice(1, 0, "");
        console.log(lines);
        let newtext = lines.join("\n");
        console.log(newtext);
        setReadable({ md: newtext });
      });
  }, [file]);

  return (
    <>
      <Box>
        <UnorderedList mx={0} px={0}>
          <ReactMarkdown
            children={readable.md}
            rehypePlugins={[rehypeRaw]}
            components={{
              figure: ({ node, ...props }) => (
                <figure
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "75%",
                    margin: "auto",
                  }}
                  {...props}
                />
              ),
              blockquote: ({ node, ...props }) => (
                <blockquote
                  style={{
                    fontWeight: "bold",
                    borderLeft: "3px solid #255A94",
                  }}
                  {...props}
                />
              ),
              p: ({ node, ...props }) => (
                <Text my={8} px={[0, null, null, 4, 8]} {...props} />
              ),
              h1: ({ node, ...props }) => (
                <Text
                  fontSize={["4xl", "5xl"]}
                  fontWeight="bold"
                  mb={3}
                  px={[0, null, null, 4, 8]}
                  {...props}
                />
              ),
              h2: ({ node, ...props }) => (
                <Text
                  fontSize={["2rem", "4xl"]}
                  fontWeight="bold"
                  mt={10}
                  mb={8}
                  px={[0, null, null, 4, 8]}
                  {...props}
                />
              ),
              h3: ({ node, ...props }) => (
                <Text
                  fontSize={["1.75rem", "3xl"]}
                  fontWeight="bold"
                  mt={8}
                  mb={4}
                  px={[0, null, null, 4, 8]}
                  {...props}
                />
              ),
              h4: ({ node, ...props }) => (
                <Text
                  fontSize={["1.4rem", "2xl"]}
                  fontWeight="bold"
                  mt={10}
                  mb={4}
                  px={[0, null, null, 4, 8]}
                  {...props}
                />
              ),
              h5: ({ node, ...props }) => (
                <Text
                  fontSize="xl"
                  fontWeight="regular"
                  mb={6}
                  px={[0, null, null, 4, 8]}
                  {...props}
                />
              ),
              ul: ({ node, ...props }) => (
                <chakra.ul pl={[4, null, null, null, 8]} {...props} />
              ),
              li: ({ node, ...props }) => <ListItem my={4} {...props} />,
              a: ({ node, ...props }) => (
                <chakra.a
                  color="blue"
                  _hover={{ textDecoration: "underline" }}
                  {...props}
                />
              ),
            }}
          />
        </UnorderedList>

        <Box px={[0, null, null, 4, 8]} mt={8}>
          <Divider mb={8} />
          <Bio />
        </Box>
      </Box>
      {/* <Box>
        <Text variant="subtitle" mb={2}>
          My Blog
        </Text>
        <Text>Redirecting...</Text>
      </Box> */}
    </>
  );
};

export default Post;
